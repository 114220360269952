import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { Link,useNavigate} from "react-router-dom";
import "./pdf.css";

function Accepted() {
  const [view, setView] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pdfPerPage = 8;
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("http://localhost:3005/Accepted")
      .then((response) => {
        setView(response.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, []);

  const totalPages = Math.ceil(view.length / pdfPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleViewPdf = (filePath) => {
    const fileName = filePath.split('/').pop();
    window.open(`https://api.juristbot.ai/pdfs/${fileName}`, "_blank");
  };

  const currentPdfs = view.slice((currentPage - 1) * pdfPerPage, currentPage * pdfPerPage);
  const handleLogout = () => {
    navigate("/");
  };
  return (
    <div>
          <nav className="navbar navbar-dark earningnav1 sticky-top navbar-expand-lg">
        <div className="container-fluid">
        <Link to="/pdfdata"> <img
            src={require("../login/framlogo.png")}
            alt="logo"
            className="top-logo"
          />
        </Link>
          <FontAwesomeIcon
            icon={faBars}
            className="navbar-toggler text-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          />
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto uldiv">
              <div className="nav-item texnav">
                <button className="log-outbtn mt-1" onClick={handleLogout}>
                  Log out
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <ToastContainer />
      <div className="container-fluid">
        <div className="tabledivs container mt-4">
          <div className="table-wrapper">
            <div className="table-responsive">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Main Name</th>
                    <th>Draft Name</th>
                    <th>Status</th>
                    <th>PDF</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPdfs.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * pdfPerPage + index + 1}</td>
                      <td>{item.maindraft_name}</td>
                      <td>{item.subdraft_name}</td>
                      <td className="text-success">{item.satus}</td>
                      <td>
                        <button
                          type="button"
                          onClick={() => handleViewPdf(item.file_path)}
                          className="view"
                        >
                          Download
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <div>
                <span>Page {currentPage} of {totalPages}</span>
              </div>
              <div>
                <span
                  className={`material-symbols-outlined arrorleft-icon ${currentPage === 1 ? "disabled" : ""}`}
                  onClick={handlePreviousPage}
                >
                  arrow_back_ios
                </span>
                <span
                  onClick={handleNextPage}
                  className={`material-symbols-outlined arroright-icon ${currentPage === totalPages ? "disabled" : ""}`}
                >
                  arrow_forward_ios
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Accepted;
