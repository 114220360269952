import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import "./pdf.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";

function Pdfview() {
  const [view, setView] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const pdfPerPage = 8;
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://api.juristbot.ai/uploads")
      .then((response) => {
        setView(response.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, []);

  const totalPages = Math.ceil(view.length / pdfPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const indexOfLastEmail = currentPage * pdfPerPage;
  const indexOfFirstEmail = indexOfLastEmail - pdfPerPage;
  const currentEmails = view.slice(indexOfFirstEmail, indexOfLastEmail);

  const handleLogout = () => {
    navigate("/");
  };

  const handleViewPdf = (filePath, userId) => {
    navigate(`/viewpdf/${encodeURIComponent(filePath)}/${userId}`);
  };

  const filteredEmails = currentEmails.filter((item) =>
    item.maindraft_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <nav className="navbar navbar-dark earningnav1 sticky-top navbar-expand-lg">
        <div className="container-fluid">
          <img
            src={require("../login/framlogo.png")}
            alt="logo"
            className="top-logo"
          />
          <FontAwesomeIcon
            icon={faBars}
            className="navbar-toggler text-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          />
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto uldiv">
              <div className="nav-item texnav">
               <Link to="/Accepted" className="acceptedtext"> <h6 className="nav-link pb-0 acceptdark">Accepted</h6></Link>
              </div>
              <div className="nav-item texnav">
                <button className="log-outbtn mt-1" onClick={handleLogout}>
                  Log out
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="search-bar">
            <FontAwesomeIcon className="search-icon" icon={faSearch} />
            <input
              type="search"
              className="search-bar-input"
              placeholder="Search Draft Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="tabledivs container mt-3">
          <div className="table-wrapper">
            <div className="table-responsive">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>MainDraftName</th>
                    <th>SubDraftName</th>
                    <th>Pdf</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEmails.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.upload_date}</td>
                      <td>{item.user_name}</td>
                      <td>{item.maindraft_name}</td>
                      <td>{item.subdraft_name}</td>
                      <td>
                        <button
                          type="button"
                          onClick={() =>
                            handleViewPdf(item.file_path, item.user_id)
                          }
                          className="view"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <div>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
              </div>
              <div>
                <span
                  className="material-symbols-outlined arrorleft-icon"
                  onClick={handlePreviousPage}
                >
                  arrow_back_ios
                </span>
                <span
                  onClick={handleNextPage}
                  className={`material-symbols-outlined arroright-icon ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  arrow_forward_ios
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pdfview;
