import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/login/login";
import Pdfview from "./components/page/pdftable";
import Viewpdf from "./components/page/viewpdf";
import PrivateRoute from "./components/privateroute";
import Accepted from "./components/page/Accepted";

function App() {
  const isAuthenticated = true; 

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
          <Route path="/pdfdata" element={<Pdfview />} />
          <Route path="/viewpdf/:filePath/:user_id" element={<Viewpdf />} />
          <Route path="/Accepted" element={<Accepted/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
