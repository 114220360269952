import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import logo from "../login/framlogo.png";
import "./login.css";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios.post(`https://api.juristbot.ai/admin/adminlogin`, {
        email,
        pass_word: password,
      });
      toast.success("Login Successful");
      setTimeout(() => {
        navigate("/pdfdata");
      }, 1000);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error("Network error. Try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-5 col-md-0 backimagelogin col-sm-0 col-0"></div>
        <div className="col-lg-7 col-md-12 col-sm-12 col-12">
          <div className="registration-Loginin">
            <div className="logo-container">
              <img src={logo} alt="logo" className="logoimagereg" />
            </div>
            <h4 className="admintext mt-3 mb-0">Hi there!</h4>
            <p className="admintextp">
              This website is only for Juristbot admin
            </p>
            <h4 className="logoin mt-2">Login</h4>
            <form onSubmit={handleLogin}>
              <div>
                <label className="reglable mt-2">Email</label>
                <input
                  type="email"
                  className="reg-form mt-1"
                  value={email}
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <h6 className="reglable mt-3">Password</h6>
              <div className="password-input-container mt-2">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  className="reg-form"
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  aria-describedby="passwordHelpBlock"
                />
                <span
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                  aria-label={showPassword ? "Hide password" : "Show password"}
                  role="button"
                  tabIndex="0"
                  onKeyPress={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                    className="eyepassword"
                    icon={showPassword ? faEyeSlash : faEye}
                  />
                </span>
              </div>
              <button
                className="regformbtn1 mt-3"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Logging in..." : "Login"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default LoginForm;
