import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './pdf.css';

function Viewpdf() {
  const { filePath, user_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast.error('Failed to load PDF.');
    }
  }, [error]);

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
    setError(true);
  };

  const updateStatus = async (satus) => {
    try {
      const response = await axios.put(`https://api.juristbot.ai/uploads/${encodeURIComponent(filePath)}`, { satus, user_id });
      if (response.data && response.data.message) {
        toast.success(response.data.message);
      } else {
        toast.error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
    }
  };

  const handleReject = () => {
    updateStatus('Rejected');
    navigate("/pdfdata");
  };

  const handleAccept = () => {
    updateStatus('Accepted');
    navigate("/pdfdata");
  };

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row earningnav sticky-top">
        <div className="col-8 col-lg-10 col-md-7 col-sm-7">
          <Link to="/pdfdata">
            <img
              src={require("../login/framlogo.png")}
              alt="logo"
              className="top-logo"
            />
          </Link>
        </div>
        <div className="col-2 col-lg-1 col-md-2 col-sm-3">
          <div className="d-flex justify-content-end">
            <button className="log-outbtn" onClick={handleReject}>
              Reject
            </button>
          </div>
        </div>
        <div className="col-2 col-lg-1 col-md-2 col-sm-2">
          <div className="d-flex justify-content-end">
            <button className="Accepted" onClick={handleAccept}>
              Accept
            </button>
          </div>
        </div>
      </div>
      <div className="pdf-viewer">
        {loading && <div className="loading-indicator">Loading...</div>}
        {!error && (
          <iframe
            src={`https://api.juristbot.ai/view/${decodeURIComponent(filePath)}`}
            className="responsive-iframe"
            title="PDF Viewer"
            onLoad={handleLoad}
            onError={handleError}
          ></iframe>
        )}
      </div>
    </div>
  );
}

export default Viewpdf;
